<script setup lang="ts">
import {AssociativeArray} from "~/types/AssociativeArray";
import {BevcoProductPrice} from "~/types/BevcoProductPrice";
import {AlgoliaProduct} from "~/types/AlgoliaResult";
import {SharedProductSliderConfig} from "~/types/SharedProductSliderConfig";
import ProductCard from "~/components/product/ProductCard.vue";
import useAlgolia from "~/composables/useAlgolia";

const props = defineProps<{
    products: AlgoliaProduct[] | undefined;
    config: SharedProductSliderConfig | undefined;
}>();

const {addSearchHistory, algoliaTrackClick} = useAlgolia();
const productPrices = inject("productPrices") as AssociativeArray<BevcoProductPrice>;
const displayGross = inject("displayGross") as boolean;
const sliderElem = ref(null);

const sliderNavPrev = ref<HTMLElement|null>(null);
const sliderNavNext = ref<HTMLElement|null>(null);

const breakpoints = {
    768: {
        slidesPerView: 3,
        spaceBetween: 24
    },
    1024: {
        slidesPerView: 4,
        spaceBetween: 24
    },
    1536: {
        slidesPerView: 5,
        spaceBetween: 24
    },
};

const swiper = ref(null);

const nextSlide = () => swiper.value.slideNext();
const prevSlide = () => swiper.value.slidePrev();

const swiperProgress = (swiper, progress) => {
    const toggleClass = 'opacity-0';
    if (progress === 1) {
        sliderNavNext.value?.classList.add(toggleClass);
    } else {
        sliderNavNext.value?.classList.remove(toggleClass);
    }

    if (progress === 0) {
        sliderNavPrev.value?.classList.add(toggleClass);
    } else {
        sliderNavPrev.value?.classList.remove(toggleClass);
    }

};

</script>

<template>
<div class="cms-element-product-slider carousel-element">
    <div class="flex justify-between py-[25px] line-height-none">
        <span v-if="config?.title" class="text-[18px] md:text-[22px] font-semibold">
            {{ config?.title }}
        </span>
        <span class="pt-[2px] min-w-[115px] text-right" v-if="config?.link">
            <NuxtLink :href="config.link" class="border-b border-b-solid">{{ $t('listing.seeAll') }}<template v-if="config.nbHits"> ({{ config.nbHits }})</template></NuxtLink>
        </span>
    </div>
    <div v-if="products ? products.length > 0 : false" class="relative" :class="{ 'border border-gray-300': props.config?.border }">
        <Swiper
            ref="sliderElem"
            space-between="10"
            :breakpoints="breakpoints"
            @swiper="(swiperInstance) => swiper = swiperInstance"
            @progress="swiperProgress"
            :slides-per-view="2"
            :css-mode="true"
            :mousewheel="{forceToAxis: true, releaseOnEdges: true}"
            :speed="100"
            :grab-cursor="true"
        >
            <SwiperSlide v-for="(product, index) of products" :key="product.objectID">
                <div
                    class="
                        relative bg-white
                        p-[10px]
                        md:px-[15px]
                        lg:px-[20px]
                        xl:px-[25px] xl:pt-[10px] xl:pb-[15px]
                    "
                >
                    <ProductCard
                        class="h-full"
                        :product="product"
                        :layoutType="props.config?.layoutType"
                        displayMode="contain"
                        :price="productPrices[product.objectID]"
                        :display-gross="displayGross"
                        @click="[
                            addSearchHistory(product.objectID, product.queryID ?? null),
                            algoliaTrackClick(product.objectID, 'ProductSlider: Product Clicked', product.queryID ?? null, product.queryID ? index+1 : null),
                        ]"
                    />
                </div>
            </SwiperSlide>
        </Swiper>
        <div ref="sliderNavPrev" class="slider-nav-prev" @click="prevSlide"><NuxtImg src="/arrow-left-bold.svg" width="7" height="13"/></div>
        <div ref="sliderNavNext" class="slider-nav-next" @click="nextSlide"><NuxtImg src="/arrow-left-bold.svg" width="7" height="13" class="rotate-180"/></div>
    </div>
</div>
</template>
<style scoped>
.slider-nav-prev, .slider-nav-next {
    display: inline-block;
    background: #fff;
    border-radius: 50%;
    cursor: pointer;
    height: 40px;
    width: 40px;
    box-shadow: 0px 3px 6px #00000029;

    position: absolute;
    top: calc(50% - 20px);
    z-index: 1;

    transition: opacity 0.2s;

}

.slider-nav-prev {
    left: -10px;
    padding: 10px 16px;
}

.slider-nav-next {
    right: -10px;
    padding: 10px 18px;
}
</style>
